// @parsec
import { runAuthRedirect } from '@parsec/redirect';

import { LogIn } from 'components';
import { useEnsuredLoggedOut } from 'lib/hooks';

const LogInPage: NextPage = () => {
  if (!useEnsuredLoggedOut()) return null;

  return (
    <LogIn
      showSamlLink
      showSignupLink={true}
      onSuccess={() => runAuthRedirect('/plan')}
    />
  );
};

export default LogInPage;
